:global {
    :local(.wrapper) {
        height: 100%;

        >.top {
            height: 949px;
            display: flex;
            justify-content: space-between;

            >.item {
                width: 900px;
                background-image: url(./res/bck_1.svg);
                background-size: 100% 100%;
            }

            >.item_mid {
                width: 1848px;
            }
        }

        >.bottom {
            margin-top: 40px;
            height: 452px;
            display: flex;
            justify-content: space-between;

            >.item {
                width: 1216px;
                background-image: url(./res/bck_2.svg);
                background-size: 100% 100%;
            }
        }
    }
}