:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;

        >.top {
            display: flex;

            >.left {
                padding: 50px 0 0 56px;
                color: rgba(63, 202, 241, 1);
                font-size: 30px;
                line-height: 42px;
            }

            >.right {
                margin-left: auto;
                padding-bottom: 4px;
                padding-right: 56px;
                align-self: flex-end;
                display: flex;
                align-items: center;

                >.item {
                    display: flex;
                    align-items: center;

                    &:nth-child(n+2) {
                        margin-left: 24px;
                    }

                    >.line {
                        width: 32px;
                        height: 1px;
                        background-image: linear-gradient(to right, rgba(251, 152, 49, 1) 12px, transparent 12px, transparent 20px, rgba(251, 152, 49, 1) 20px, rgba(251, 152, 49, 1) 32px);
                        position: relative;

                        &::after {
                            content: "";
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 8px;
                            height: 8px;
                            border: 2px solid rgba(251, 152, 49, 1);
                            border-radius: 50%;
                        }
                    }

                    >.bar {
                        width: 24px;
                        height: 8px;
                        border-radius: 2px;
                        background-color: rgba(39, 229, 240, 1);
                    }

                    >.name {
                        margin-left: 8px;
                        font-size: 20px;
                        line-height: 28px;
                        color: rgba(255, 255, 255, 1);
                    }
                }
            }
        }

        >.bottom {
            height: 0;
            flex-grow: 1;

            >.chart {
                height: 100%;
            }

        }
    }
}