:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;

        >.top {
            padding-top: 16px;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;

            >.item {
                display: flex;
                align-items: center;

                &:nth-child(n+2) {
                    margin-left: 50px;
                }

                >.bar {
                    width: 24px;
                    height: 8px;
                    border-radius: 2px;
                }

                >.name {
                    margin-left: 8px;
                    font-size: 20px;
                    line-height: 28px;
                    color: rgba(255, 255, 255, 1);
                }
            }
        }

        >.bottom {
            height: 0;
            flex-grow: 1;
            display: flex;

            >.left,
            >.right {
                display: flex;
                flex-direction: column;

                >.top {
                    padding-top: 34px;
                    font-size: 20px;
                    line-height: 28px;
                    color: rgba(255, 255, 255, 1);
                    text-align: center;
                }

                >.bottom {
                    height: 0;
                    flex-grow: 1;
                }
            }

            >.left {
                width: 0;
                flex-grow: 1;
            }

            >.right {
                width: 876px;
            }
        }
    }
}