:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;

        >.top {
            display: flex;

            >.left {
                padding: 50px 0 0 56px;
                color: rgba(63, 202, 241, 1);
                font-size: 30px;
                line-height: 42px;
            }

            >.right {
                align-self: flex-end;
                padding-bottom: 4px;
                padding-right: 56px;
                margin-left: auto;
                display: flex;
                align-items: center;

                >.item {
                    display: flex;
                    align-items: center;

                    &:nth-child(n+2) {
                        margin-left: 24px;
                    }

                    >.bar {
                        width: 24px;
                        height: 8px;
                        border-radius: 2px;
                    }

                    >.name {
                        margin-left: 8px;
                        font-size: 20px;
                        line-height: 28px;
                        color: rgba(255, 255, 255, 1);
                    }
                }
            }
        }

        >.bottom {
            height: 0;
            flex-grow: 1;

            >.chart {
                height: 100%;
            }

        }
    }
}