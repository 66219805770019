:global {
  body {
    margin: 0;
    height: 100%;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
  }

  /* code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  } */
  html {
    height: 100%;
  }

  body>#root {
    height: 100%;
  }

  .scroll_bar_restyle {
    //hardcode

    //滚动条背景
    &::-webkit-scrollbar {
      width: 8px;
      background-color: rgba(0, 0, 0, 0);
    }

    // //滚动条前景
    &::-webkit-scrollbar-thumb {
      background-color: rgba($color:#357CF7, $alpha: 0.3);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 3px rgba(100, 100, 100, 0.3);
      background-color: #E9E9E918;
      border-radius: 5px;
    }
  }

}